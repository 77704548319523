// src/context/ColorModeContext.js
import React, { createContext, useState, useContext, useEffect, PropsWithChildren } from 'react'
import { TColorMode } from '~/types'

const ColorModeContext = createContext({ colorMode: 'light', toggleColorMode: () => {} })

export const ColorModeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [colorMode, setColorMode] = useState<TColorMode>(() => {
    // ローカルストレージから保存されたカラー設定を取得
    const savedMode = localStorage.getItem('color-mode') as TColorMode | null
    return savedMode ? savedMode : 'light'
  })

  useEffect(() => {
    // カラー設定をローカルストレージに保存
    localStorage.setItem('color-mode', colorMode)
    document.body.className = colorMode // クラス名をボディに適用（例: "light" または "dark"）
  }, [colorMode])

  const toggleColorMode = () => {
    setColorMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
  }

  return (
    <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
      {children}
    </ColorModeContext.Provider>
  )
}

export const useColorMode = () => useContext(ColorModeContext)
